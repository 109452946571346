<template>
  <div>

    <v-card
        class="chat-message pa-3"
        :class="{'sender mr-9': recipient === 'sender', 'receiver ml-9': recipient === 'receiver'}"
        ripple
    >
      {{ message.body }}
    </v-card>
    <div
        :class="{'text-right mr-9': recipient === 'sender', 'text-left ml-9': recipient === 'receiver', }"
    >
      <span v-if="recipient === 'sender'" class="text--secondary">read</span>
      <span v-if="recipient === 'receiver'">{{ message.sender.full_name }}</span>
      <v-icon x-small class="mx-1" style="font-size: 4px;">mdi-circle</v-icon>

      <span class="text--secondary">{{ timeSince(message.created_at) }}</span>

      <v-icon v-if="recipient === 'sender'" x-small class="mx-1" style="font-size: 4px;">mdi-circle</v-icon>
      <span v-if="recipient === 'sender'">You</span>
    </div>
    <div
        :style="{'background-image': 'url('+message.sender.profile_picture+')'}"
        class="avatar"
        :class="{'avatar-sender': recipient === 'sender', 'avatar-receiver': recipient === 'receiver'}"
    ></div>

  </div>
</template>

<script>
import moment from 'moment-timezone';
export default {
  name: "ChatMessage",
  props: {
    recipient: {
      type: String,
      default: 'sender',
    },
    message: {
      type: Array/Object,
      default: [],
    }
  },
  methods: {
    timeSince(date) {
      return moment(date).fromNow();
    },
  },
}
</script>
<style scoped>
.chat-message {
  width: 362px;
}
.sender {
  border-radius: 11px 11px 0 11px;
  color: #FFFFFF;
  background-color: #929292;
  margin-left: auto;
}
.chat-text {
  margin-right: 35px;
}
.receiver {
  border-radius: 11px 11px 11px 0;
  color: #333333;
  background-color: #F1F1F1;
  margin-right: auto;
}
.avatar {
  height: 31px;
  width: 31px;
  background-color: #929292;
  border-radius: 20px;
  background-size: cover;
  margin-top: -30px;
}
.avatar-sender {
  margin-left: auto;
}
.avatar-recever {
  margin-right: auto;
}
</style>